import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Layout from '../components/layout/layout';
import { IMPRINT_QUERY } from '../content/datoCms/queries';
import { device } from '../styles/devices';
import { GlobalStyles } from '../styles/globalStyles';
import theme from "../styles/theme"
import BackArrow from "../svg/back-arrow.svg";
import { getCmsContent } from '../content/datoCms/cmsContent';
import { HEADING, LIST } from '../content/datoCms/imprintContentTypes';
import { getDefaultImprintContent } from '../content/defaultContent';

const ImprintWrapper = styled.div`
	padding: 1rem;
	hyphens: auto;
	position: relative;

	#imprint, p {
		white-space: pre-line;
	}

	.back-arrow {
		position: absolute;
		right: 1rem;
		color: ${props => props.theme.red};
		width: 50px;

		@media ${device.laptop} {
			display: none;
		}
	}

	a {
		word-break: break-all;
	}

	h2, h3, h4 { font-family: 'New Paris Skyline', sans-serif; word-break: break-word; }
	h1 {
		font-family: 'Sang Bleu Empire';
		color: ${props => props.theme.red};
		overflow-y: hidden;
	}

	@media ${device.laptop} {
		padding: .67rem 0 0 15%;
	}
`;

const Paragraph = styled.p`
  	letter-spacing: .5px;
`;

const Li = styled.li`
  	font-family: 'New Paris Skyline', sans-serif;
`;

export default class Impressum extends Component {
	constructor(props){
		super(props);

		this.state = {
			content: getDefaultImprintContent(),
		}

		getCmsContent(IMPRINT_QUERY)
			.then(result => this.setState({ content: result.data.impressum.inhalt.value.document.children }))
			.catch(error => console.log(error));
	}

	createHeaderNode(node, key){
		let text = node.children[0].value;
		switch (node.level) {
			case 1: 
				return <h1 key={key}>{ text }</h1>;
			case 2:
				return <h2 key={key}>{ text }</h2>;
			case 3:
				return <h3 key={key}>{ text }</h3>;
			case 4:
				return <h4 key={key}>{ text }</h4>;
			case 5:
				return <h5 key={key}>{ text }</h5>;
			default:
				return <Paragraph key={key}>{ text }</Paragraph>;
			}
	}

	createParagraphNode(node, index){
		return (
			<Paragraph key={index}>
				{
					node.children.map((subNode, key) => {
						if(subNode.type === 'link'){
							return <a key={key} href={subNode.url}>{ subNode.children[0].value }</a>
						}

						if(subNode.marks?.includes('strong')){
							return <b key={key}>{ subNode.value }</b>
						}
						
						return <span key={key}>{ subNode.value }</span>
					})
				}
			</Paragraph>
		)
	}

	createBulletList(node, index){
		return (
			<ul key={index}>
				{
					node.children.map((subNode, key) => (
						<Li key={key}>{ subNode.children[0].children[0].value }</Li>
					))
				}
			</ul>
		);
	}

	render(){
		return (
      <ThemeProvider theme={theme}>
				<GlobalStyles />
				<Layout colorsInverted>
				<ImprintWrapper>
					<div id="imprint">
						<AnchorLink to="/">
							<BackArrow className="back-arrow" />
						</AnchorLink>

						{
							this.state.content.map((node, index) => {
								if(node.type === HEADING){
									return this.createHeaderNode(node, index);
								}

								if(node.type === LIST){
									return this.createBulletList(node, index);
								}

								return this.createParagraphNode(node, index);
							})
						}

					</div>
				</ImprintWrapper>
				</Layout>
      </ThemeProvider>
  	);
	}
}
